<template>
  <TTView>
    <VRow>
      <VCol>
        <PositionCard
          :companies="companies"
          :position="position"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <CompetenciesTable
          :companies="companies"
          :competencies="competencies"
          :loading="loading"
          :total="competenciesTotal"
          @on-change-page="onChangePage"
          @on-change-limit="onChangeLimit"
        >
          <template #rowActions="{ item }">
            <VTooltip
              v-if="!matrixHasCompetency(item)"
              left
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="green"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="addCompetency(item)"
                >
                  <VIcon small>
                    fal fa-plus
                  </VIcon>
                </VBtn>
              </template>

              <span>Добавить</span>
            </VTooltip>

            <VTooltip
              v-else
              left
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteCompetency(item)"
                >
                  <VIcon small>
                    fal fa-minus
                  </VIcon>
                </VBtn>
              </template>

              <span>Удалить</span>
            </VTooltip>
          </template>
        </CompetenciesTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_POSITION } from '../../components/competency-matrix/positions/common';
import PositionCard from '../../components/competency-matrix/positions/PositionCard.vue';
import CompetenciesTable from '../../components/competency-matrix/competencies/CompetenciesTable.vue';

export default {
  name: 'PositionCompetencies',

  components: {
    PositionCard,
    CompetenciesTable,
  },

  data() {
    return {
      companies: [],
      competencies: [],
      loading: false,
      matrix: [],
      position: { ...DEFAULT_POSITION },
      currentPage: 1,
      currentLimit: 10,
      competenciesTotal: 0,
    };
  },

  computed: {
    matrixIds() {
      return this.matrix.map(({ id }) => id);
    },

    matrixHasCompetency() {
      return (competency) => this.matrixIds.includes(competency.id);
    },
  },

  async created() {
    await this.fetchPosition();
    await this.fetchCompanies();
    await this.fetchCompetencies();
  },

  methods: {
    async fetchPosition() {
      try {
        this.loading = true;
        const { positionId: id } = this.$route.params;
        const data = { id };
        const { matrix, position } = await this.$di.api.CompetencyMatrix.PositionsGet(data);
        this.matrix = matrix || [];
        this.position = position || { ...DEFAULT_POSITION };
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    // TODO: ручка очень тяжелая, пагинация не спасет, т.к нужно компании сопоставлять с компетенциями
    // Нужно ручку компетенций обогатить параметром company_name, тогда тут от indexCompany можно будет отказаться
    async fetchCompanies() {
      try {
        this.loading = true;
        const { companies } = await this.$di.api.Account.indexCompany();
        this.companies = companies || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchCompetencies() {
      try {
        this.loading = true;
        const { competencies, total } = await this.$di.api.CompetencyMatrix.CompetenciesIndexWithPagination({
          page: this.currentPage,
          limit: this.currentLimit,
        });
        this.competencies = competencies || [];
        this.competenciesTotal = total || 0;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        this.loading = false;
      }
    },

    async addCompetency(item) {
      try {
        this.loading = true;
        const { positionId: id } = this.$route.params;
        const { id: competencyId } = item;
        const data = { id, competencyId };

        await this.$di.api.CompetencyMatrix.PositionsAddCompetency(data);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    async deleteCompetency(item) {
      try {
        this.loading = true;
        const { positionId: id } = this.$route.params;
        const { id: competencyId } = item;
        const data = { id, competencyId };

        await this.$di.api.CompetencyMatrix.PositionsRemoveCompetency(data);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    onChangePage(page) {
      this.currentPage = page;
      this.fetchCompetencies();
    },

    onChangeLimit(limit) {
      this.currentLimit = limit;
      this.fetchCompetencies();
    },
  },
};
</script>
