var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('PositionCard',{attrs:{"companies":_vm.companies,"position":_vm.position}})],1)],1),_c('VRow',[_c('VCol',[_c('CompetenciesTable',{attrs:{"companies":_vm.companies,"competencies":_vm.competencies,"loading":_vm.loading,"total":_vm.competenciesTotal},on:{"on-change-page":_vm.onChangePage,"on-change-limit":_vm.onChangeLimit},scopedSlots:_vm._u([{key:"rowActions",fn:function(ref){
var item = ref.item;
return [(!_vm.matrixHasCompetency(item))?_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"green"},on:{"click":function($event){return _vm.addCompetency(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Добавить")])]):_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteCompetency(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }